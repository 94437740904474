export const getLaunchUrlByDevice = (autoStartToken, redirectUrl) => {
    // Validate inputs
    if (!autoStartToken || !redirectUrl) {
        throw new Error('Missing required parameters');
    }

    // Safe URL construction
    const safeRedirect = new URL(redirectUrl);
    safeRedirect.hash = "#initiated=true";
    
    // Build base URL based on platform
    let baseUrl;
    switch(true) {
        case isAndroid():
            baseUrl = `bankid:///?autostarttoken=${autoStartToken}`;
            break;
        case isSafariOniOS():
            baseUrl = `bankid:///?autostarttoken=${autoStartToken}`;
            break;
        default:
            baseUrl = `https://app.bankid.com/?autostarttoken=${autoStartToken}`;
    }

    // Add encoded redirect parameter
    return `${baseUrl}&redirect=${encodeURIComponent(safeRedirect.href)}`;
};

const isMobileDevice = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    return /android|ipad|iphone|ipod/i.test(userAgent);
};

const isSafariOniOS = () => {
    const userAgent = navigator.userAgent;
    return /iP(ad|od|hone).*Safari/i.test(userAgent) && !/CriOS|Chrome/i.test(userAgent);
};

const isChromeOnIOS = () => {
    return /CriOS/i.test(navigator.userAgent);
};

const isFirefoxOnIOS = () => {
    return /FxiOS/i.test(navigator.userAgent);
};


export const isAndroid = () => 
    /android/i.test(navigator.userAgent) && !/windows phone/i.test(navigator.userAgent);
  
const isChromeOnAndroid = () => 
    isAndroid() && /Chrome\/[.0-9]* Mobile/i.test(navigator.userAgent);

export const isAnyMobileDevice = () => 
    isMobileDevice() || 
    isSafariOniOS() || 
    isChromeOnIOS() || 
    isFirefoxOnIOS() || 
    isAndroid();