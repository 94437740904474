<template>
  <div
    class="d-flex flex-column animate__animated animate__fadeIn"
    style="width: 100%"
  >
    <h2 class="mb-6">
      Logga in
    </h2>
    <v-btn
      v-if="!isAnyMobileDevice()"
      id="login-bankid-btn"
      type="submit"
      class="mb-6"
      light
      rounded
      depressed
      x-large
      @click="showQRCodeDialog = true"
    >
      <img
        :src="bankidBlack"
        alt="BankID logo"
        style="width: 40px; margin-right: 4px"
      >
      Mobilt BankID
    </v-btn>
    <v-btn
      v-else
      id="open-bankid-btn"
      type="submit"
      class="mb-6"
      light
      rounded
      depressed
      x-large
      @click="authLoginStart()"
    >
      <img
        :src="bankidBlack"
        alt="BankID logo"
        style="width: 40px; margin-right: 4px"
      >
      Öppna BankID
    </v-btn>
    <v-btn
      v-if="isAnyMobileDevice()"
      color="secondary"
      class="secondary--text mb-6"
      rounded
      outlined
      x-large
      depressed
      @click="onStartBankIDOnAnotherDevice()"
    >
      BankID på annan enhet
    </v-btn>
    <a
      class="mb-16"
      href="https://gigport.se/mobilt-bankid-dator/"
      target="_blank"
      rel="noopener"
    >
      <v-icon color="#FFB74D">mdi-open-in-new</v-icon>
      Så loggar du in med BankID</a>
    <h2 class="mb-6">
      Har du inte GigPort?
    </h2>
    <v-btn
      color="secondary"
      class="secondary--text mb-6"
      rounded
      outlined
      x-large
      depressed
      :to="{ name: 'register-user' }"
    >
      Skapa konto
    </v-btn>
    <QRCodeDialog
      v-if="showQRCodeDialog"
      :show="showQRCodeDialog"
      qrcode-type="login"
      @cancel-login="onCancelLogin($event)"
    />
  </div>
</template>

<script>
import { isAnyMobileDevice, getLaunchUrlByDevice } from "@/utils/utils";
import bankidBlack from "@/assets/img/bankid_black.svg";
import {
  AUTH_LOGIN_COLLECT,
  AUTH_LOGIN_MOBILE_COLLECT,
  AUTH_LOGIN_START,
} from "@/store/actions.type";
import QRCodeDialog from "@/components/accounts/QRCodeDialog.vue";

export default {
  name: "LoginView",
  components: {
    QRCodeDialog,
  },
  data: () => ({
    isAnyMobileDevice,
    getLaunchUrlByDevice,
    bankidBlack,
    showQRCodeDialog: false,
    sameDeviceUrl: null,
    orderRef: "",
  }),
  watch: {
    "$route.query.orderRef": "handleOrderRefChange",
    sameDeviceUrl(newUrl) {
      if (newUrl) {
        window.location.href = newUrl;
      }
    },
  },
  mounted() {
    if (window.location.hash === "#initiated=true") {
      window.location.hash = "";
    }
    if (this.$route.query.orderRef) {
      this.orderRef = this.$route.query.orderRef;
      this.collect(this.orderRef, this.isAnyMobileDevice());
    }
  },
  methods: {
    onCancelLogin(err) {
      if (err) {
        this.handleLoginError(err);
      }
      this.showQRCodeDialog = false;
    },
    onStartBankIDOnAnotherDevice() {
      this.showQRCodeDialog = true;
    },
    authLoginStart() {
      this.$store
        .dispatch(AUTH_LOGIN_START, { deviceIdentifier: "mobile" })
        .then((res) => {
          let redirectUrl = window.location.href;
          redirectUrl += this.$route.query.redirect
            ? `&orderRef=${res.data.orderRef}`
            : `?orderRef=${res.data.orderRef}`;
          this.sameDeviceUrl = this.getLaunchUrlByDevice(
            res.data.autoStartToken,
            redirectUrl
          );
        })
        .catch((err) => {
          this.handleLoginError(err);
          // this.$root.$emit("errorMessage", err.response);
        });
    },
    handleLoginError(err) {
      if (err.response.data.errorCode === "409E002") {
        this.alert.text = this.$t("Validation.email_in_use");
      } else if (err.response.data.errorCode === "409E003") {
        this.alert.text = this.$t("Validation.account_with_nin");
      } else {
        this.alert.text = err.response.data.errorMessage;
      }
      this.alert.visible = true;
    },
    handleOrderRefChange(newVal) {
      if (newVal) {
        this.orderRef = newVal;
        this.collect(newVal, this.isAnyMobileDevice());
      }
    },
    collect(orderRef, isMobile) {
      const path = isMobile ? AUTH_LOGIN_MOBILE_COLLECT : AUTH_LOGIN_COLLECT;
      this.$store
        .dispatch(path, { orderRef: orderRef })
        .then((res) => {
          if (res.token && res.status === "complete") {
            this.$gtm.trackEvent({
              category: 'Growth',
              action: 'New-signup',
              label: 'New signup',
              value: 0
            })
            this.$store.dispatch("GET_USER");
            this.$router.push({ name: "dashboard" });
          }
          if (res.data.status === "userNotFound") {
            this.onCancelLogin();
            const error = {
              message: this.$t("Validation.user_not_found"),
            }
            this.$root.$emit("errorMessage", error);
          }
        })
        .catch((err) => {
          this.handleLoginError(err);
        });
    },
  },
};
</script>